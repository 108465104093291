import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"
import Typography from "@material-ui/core/Typography"

const Chirugie = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Chirugie" />
        <Typography variant="h4" color="inherit" style={{ marginBottom: 20 }}>
          Chirugie Plastique
        </Typography>
        <Typography
          variant="body1"
          color="inherit"
          style={{ display: "block", marginBottom: 20 }}
        >
          Elle peut être envisagée après la stabilisation pondérale, soit 12 à
          18 mois après la chirurgie, en l’absence de dénutrition.
        </Typography>
        <Typography
          variant="body1"
          color="inherit"
          style={{ marginBottom: 20 }}
        >
          Si le chirurgien plasticien juge qu’une intervention est indiquée, 
          il rédige une demande d’entente préalable destinée à l’Assurance Maladie. 
          Le médecin conseil donne alors un avis positif ou négatif au remboursement 
          par la sécurité sociale. Les modalités de prise en charge peuvent varier en 
          fonction de la technique chirurgicale utilisée.
        </Typography>
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default Chirugie
